import React, {useState, useEffect, useRef} from 'react';
import {FilterMatchMode} from 'primereact/api';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {InputText} from 'primereact/inputtext';
import {Button} from 'primereact/button';
import {useTranslation} from 'react-i18next';
import {useHistory} from "react-router-dom";
import {Dialog} from 'primereact/dialog';
import {connect} from "react-redux";
import moment from 'moment';
import {exportExcel, exportPdf} from "../shared/utility";
import {SplitButton} from "primereact/splitbutton";
import {CreditService} from "../service/CreditService";
import NewReceivableDialogue from "./dialogues/NewReceivableDialogue";


const ReceivablesPage = (props) => {
    const {t, i18n} = useTranslation();

    let emptyReceivableItem = {
        id: null,
        fullName: null,
        createdDate: null,
        dueDate: null,
        notes: null,
        totalAmount: null,
        status: null,
    };

    const [receivable, setReceivable] = useState(emptyReceivableItem);
    const [receivables, setReceivables] = useState(null);
    const [selectedReceivable, setSelectedReceivable] = useState(null);
    const [receivableDialog, setReceivableDialog] = useState(false);
    const [deleteReceivableDialog, setDeleteReceivableDialog] = useState(false);
    const [submitted, setSubmitted] = useState(false);

    const [filters, setFilters] = useState({
        'global': {value: null, matchMode: FilterMatchMode.CONTAINS}
    });

    const exportOptions = [
        {
            label: 'Excel',
            icon: 'pi pi-file-excel',
            command: () => {
                exportExcel(receivables);
            }
        },
        {
            label: 'CSV',
            icon: 'pi pi-file',
            command: () => {
                exportCSV();
            }
        }
    ];

    const cols = [
        {field: 'id', header: t('id')},
        {field: 'fullName', header: t('person')},
        {field: 'totalAmount', header: t('total_amount')},
        {field: 'dueDate', header: t('date')},
        {field: 'notes', header: t('notes')},
        {field: 'createdDate', header: t('creation_date')},
    ];

    const [globalFilterValue, setGlobalFilterValue] = useState('');
    const [loading, setLoading] = useState(false);
    const toast = useRef(null);
    const dt = useRef(null);

    const creditService = new CreditService();

    useEffect(() => {
        creditService.getCredits(props.partnerId).then(data => {
            setReceivables(fixDateField(data.content));
            setLoading(false)
        });
    }, []);

    const fixDateField = (data) => {
        return [...data || []].map(d => {
            d.date = new Date(d.date);
            return d;
        });
    }

    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = {...filters};
        _filters['global'].value = value;

        setFilters(_filters);
        setGlobalFilterValue(value);
    }

    const addReceivable = () => {
        setReceivable(emptyReceivableItem);
        setSubmitted(false);
        setReceivableDialog(true);
    }

    const hideDialog = () => {
        setSubmitted(false);
        setReceivableDialog(false);
        setDeleteReceivableDialog(false);
    }

    const confirmDeleteReceivable = (receivable) => {
        setReceivable(receivable);
        setDeleteReceivableDialog(true);
    }

    const deleteReceivable = () => {
        creditService.deleteCredit(receivable.id)
            .then(() => {
                let _receivables = receivables.filter(val => val.id !== receivable.id);
                setReceivables(_receivables);
                setDeleteReceivableDialog(false);
                setReceivable(emptyReceivableItem);
            })
    }

    const exportCSV = () => {
        dt.current.exportCSV();
    }

    const onSelectionDetail = (e) => {
        setSelectedReceivable(e.value)
        setReceivableDialog(true);
    }

    const dateBodyTemplate = (rowData) => {
        if (rowData.dueDate != null) {
            return moment(rowData.dueDate).format('DD.MM.YYYY HH:mm')
        }
        return ''
    }

    const creationDateBodyTemplate = (rowData) => {
        if (rowData.createdDate != null) {
            return moment(rowData.createdDate).format('DD.MM.YYYY HH:mm')
        }
        return ''
    }

    const renderHeader = () => {
        return (
            <div className="flex justify-content-between align-items-center">
                <h5 className="m-0">{t('receivables')}</h5>
                <span className="p-input-icon-right">
                    <i className="pi pi-add"/>
                    <Button label={t('add')} icon="pi pi-plus" className="p-button-success" onClick={addReceivable}/>
                    <span> </span>
                    <i className="pi pi-export"/>
                    <SplitButton label={t('export')} icon="pi pi-download" className="p-button-help"
                                 style={{marginRight: "10px"}} onClick={() => exportPdf(cols, receivables)}
                                 model={exportOptions}/>
                    <span> </span>
                    <i className="pi pi-search"/>
                    <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder={t('search')}/>
                </span>
            </div>
        )
    }

    const header = renderHeader();
    let history = useHistory();

    const actionBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                {/*<Button icon="pi pi-pencil" className="p-button-rounded p-button-success mr-2"
                        onClick={() => editReceivable(rowData)}/>*/}
                <Button icon="pi pi-trash" className="p-button-rounded p-button-warning"
                        onClick={() => confirmDeleteReceivable(rowData)}/>
            </React.Fragment>
        );
    }

    const deleteReceivableDialogFooter = (
        <React.Fragment>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDialog}/>
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={deleteReceivable}/>
        </React.Fragment>
    );

    return (
        <div className="datatable-doc-demo col-12">
            <div className="card">
                <DataTable value={receivables} ref={dt} paginator className="p-datatable-products" header={header} rows={8}
                           paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                           rowsPerPageOptions={[10, 25, 50]}
                           dataKey="id" rowHover selection={selectedReceivable} onSelectionChange={onSelectionDetail}
                           filters={filters} filterDisplay="menu" loading={loading} responsiveLayout="scroll"
                           emptyMessage={t('no_receivable_found')}
                           currentPageReportTemplate={t('Showing {first} to {last} of {totalRecords} entries')}>
                    <Column field="fullName" header={t('person')} sortable></Column>
                    <Column field="totalAmount" header={t('total_amount')} sortable dataType="count"></Column>
                    <Column field="dueDate" header={t('date')} sortable dataType="date" body={dateBodyTemplate}></Column>
                    <Column field="notes" header={t('notes')} sortable></Column>
                    <Column field="createdDate" header={t('creation_date')} sortable dataType="date"
                            body={creationDateBodyTemplate}></Column>
                    <Column body={actionBodyTemplate} exportable={false} style={{minWidth: '8rem'}}></Column>
                </DataTable>
            </div>
            <NewReceivableDialogue visible={receivableDialog} onHide={setReceivableDialog}/>
            <Dialog visible={deleteReceivableDialog} style={{width: '450px'}} header={t('confirm')} modal
                    footer={deleteReceivableDialogFooter} onHide={hideDialog}>
                <div className="confirmation-content">
                    <i className="pi pi-exclamation-triangle mr-3" style={{fontSize: '2rem'}}/>
                    {receivable && <span>{t('confirm_delete')}</span>}
                </div>
            </Dialog>
        </div>
    );
}

const mapStateToProps = state => {
    return {
        partnerId: state.auth.userDetails.partner.id
    };
};

export default connect(mapStateToProps, null)((ReceivablesPage));
