import axios from "axios";
export class ExpenseService {
    postExpense(expense) {
        return axios.post(`${window.env.REACT_APP_GORESY_BACKEND_URL}/expense`, expense)
            .then(res => res.data);
    }

    getExpenses(partnerId) {
        return new Promise(resolve => {
            axios.get(`${window.env.REACT_APP_GORESY_BACKEND_URL}/expenses-of-partner?partnerId=${partnerId}`).then(res => resolve(res.data));
        });
    }

    deleteExpense(expenseId) {
        return new Promise(resolve => {
            axios.delete(`${window.env.REACT_APP_GORESY_BACKEND_URL}/expenses/${expenseId}`).then(res => resolve(res.data));
        });
    }

}