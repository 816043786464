import axios from "axios";

export class PackageSaleService {

  getPackageSaleList(partnerId) {
    return new Promise((resolve) => {
      axios
          .get(
              `${window.env.REACT_APP_GORESY_BACKEND_URL}/package-sales-of-partner/${partnerId}`
          )
          .then((res) => resolve(res.data));
    });
  }

  getPackageSale(packageSaleId) {
    return new Promise((resolve) => {
      axios
          .get(
              `${window.env.REACT_APP_GORESY_BACKEND_URL}/package-sale/${packageSaleId}`
          )
          .then((res) => resolve(res.data));
    });
  }

  postPackageSale(packageSale) {
    return new Promise((resolve) => {
      axios
        .post(
          `${window.env.REACT_APP_GORESY_BACKEND_URL}/package-sale`,
          packageSale
        )
        .then((res) => resolve(res.data));
    });
  }

  deletePackageSales(packageSaleId) {
    return new Promise((resolve) => {
      axios
          .delete(`${window.env.REACT_APP_GORESY_BACKEND_URL}/package-sales/${packageSaleId}`)
          .then((res) => resolve(res.data));
    });
  }

  putPackageSale(id, updatedPackageSale) {
    return new Promise((resolve) => {
      axios
          .put(
              `${window.env.REACT_APP_GORESY_BACKEND_URL}/package-sale/${id}`,
              updatedPackageSale
          )
          .then((res) => resolve(res.data));
    });
  }

}