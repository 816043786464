import React, {useEffect, useState} from 'react';
import { Tree } from 'primereact/tree';
import {useTranslation} from "react-i18next";
import {ReportService} from "../service/ReportService";
import {connect} from "react-redux";


export const CashierReportPage = (props) => {
    const {t, i18n} = useTranslation();
    const [nodes, setNodes] = useState([]);
    const [currencyType, setCurrencyType] = useState('TL');
    const [totalAmounts, setTotalAmounts] = useState({
        totalSituation: '0',
        totalIncome: '0',
        totalIncomeFromCash: '0',
        totalIncomeFromCreditCard: '0',
        totalIncomeFromOnlinePayments: '0',
        totalExpense: '0',
        totalExpenseFromCash: '0',
        totalExpenseFromCreditCard: '0',
        totalExpenseFromOnlinePayments: '0',
    });

    const reportService = new ReportService();


    useEffect(() => {
        reportService.getCashierReport(props.partnerId).then(data => {
            setTotalAmounts(data);
        });
    }, [props.partnerId]);

    const cashierReportTree = [{
            key: '0',
            label: t('total') + ` ${totalAmounts.totalSituation} ${currencyType}`,
            data: 'Total Amount of Money',
            icon: 'pi pi-fw pi-wallet',
            children: [
                {
                    key: '0-0',
                    label: t('total_income') + ` ${totalAmounts.totalIncome} ${currencyType}`,
                    data: 'Total Income Amount',
                    icon: 'pi pi-fw pi-download',
                    children: [
                        { key: '0-0-0', label: t('cash') + ` ${totalAmounts.totalIncome} ${currencyType}`, icon: 'pi pi-fw pi-money-bill', data: 'Total income amount from cash' },
                        { key: '0-0-1', label: t('credit_card') + ` ${totalAmounts.totalIncomeFromCreditCard} ${currencyType}`, icon: 'pi pi-fw pi-credit-card', data: 'Total income amount from credit card' },
                        { key: '0-0-2', label: t('online_payment') + ` ${totalAmounts.totalIncomeFromOnlinePayments} ${currencyType}`, icon: 'pi pi-fw pi-globe', data: 'Total income amount from online payments' }
                    ]
                },
                {
                    key: '0-1',
                    label: t('total_expense') + ` ${totalAmounts.totalExpense} ${currencyType}`,
                    data: 'Total Expense Amount',
                    icon: 'pi pi-fw pi-upload',
                    children: [
                        { key: '0-1-0', label: t('cash') + ` ${totalAmounts.totalExpense} ${currencyType}`, icon: 'pi pi-fw pi-money-bill', data: 'Total expense amount from cash' },
                        { key: '0-1-1', label: t('credit_card') + ` ${totalAmounts.totalExpenseFromCreditCard} ${currencyType}`, icon: 'pi pi-fw pi-credit-card', data: 'Total expense amount from credit card' },
                        { key: '0-1-2', label: t('online_payment') + ` ${totalAmounts.totalExpenseFromOnlinePayments} ${currencyType}`, icon: 'pi pi-fw pi-globe', data: 'Total expense amount from online payments' }

                    ]
                }
            ]
        }
    ]

    return (
        <div className="card">
            <Tree value={cashierReportTree} className="w-full md:w-30rem" />
        </div>
        );
}


const mapStateToProps = state => {
    return {
        partnerId: state.auth.userDetails.partner.id
    };
};

export default connect(mapStateToProps, null)((CashierReportPage));
