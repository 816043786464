import {useTranslation} from "react-i18next";
import {Dialog} from "primereact/dialog";
import React, {useRef, useState} from "react";
import {Password} from "primereact/password";
import {Button} from "primereact/button";
import {isPasswordCorrect, updatePassword} from "../../store/actions/auth";
import {connect} from "react-redux";
import {Toast} from "primereact/toast";

const ChangePasswordDialogue = (props) => {

    const {t} = useTranslation();
    const toast = useRef(null);
    const [passwordMatchOrNot, setPasswordMatchOrNot] = useState(true);
    const [password, setPassword] = useState("");
    const [currentPassword, setCurrentPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [loading, setLoading] = useState(false);


    const confirmPasswordCheck = (e) => {

        setConfirmPassword(e.target.value);
        setPasswordMatchOrNot(password === e.target.value);
    };

    const changePassword = async () => {
        if (currentPassword !== null && currentPassword !== "") {

            if (await isPasswordCorrect(props.username, currentPassword)) {
                if (password !== null && password !== "" &&
                    confirmPassword !== null && confirmPassword !== "") {
                    if (passwordMatchOrNot) {
                        setLoading(true);
                        const response = await updatePassword(props.userId, password);

                        if (response.ok) {
                            setCurrentPassword("");
                            setPassword("");
                            setConfirmPassword("");
                            setLoading(false);
                            showToastMessage("success", {
                                summary: t("success_pass_change_title"),
                                detail: t("success_pass_change"),
                            });
                            props.onHide(false);
                        } else {
                            setCurrentPassword("");
                            setPassword("");
                            setConfirmPassword("");
                            setLoading(false);
                            showToastMessage("error", {
                                summary: t("error_pass_change_title"),
                                detail: t("error_pass_change"),
                            });
                        }
                    }
                }
            } else {
                setCurrentPassword("");
                setPassword("");
                setConfirmPassword("");
                setLoading(false);
                showToastMessage("error", {
                    summary: t("error_pass_change_title"),
                    detail: t("error_pass_change"),
                });
            }

        } else {
            showToastMessage("error", {
                summary: t("error_pass_change_title"),
                detail: t("error_pass_change"),
            });
        }
    };

    const showToastMessage = (severity, messageContent) => {
        toast.current.show({
            severity: severity,
            summary: messageContent.summary,
            detail: messageContent.detail,
            life: 3000,
        });
    };

    return (
        <>
            <Toast ref={toast}></Toast>
            <Dialog header={t('change_password')} visible={props.visible} resizable={false} draggable={false}
                    dismissableMask={true} style={{width: '500px'}} onHide={props.onHide}>
                <div className="grid p-fluid">
                    <div className="col-12">
                        <div className="row mx-auto ">
                            <div className="col-lg-6 ">
                                <label
                                    htmlFor="current-password"
                                    className="block text-900 font-medium mb-2"
                                >
                                    {t("current_password")}
                                </label>
                                <Password
                                    value={currentPassword}
                                    onChange={(e) => setCurrentPassword(e.target.value)}
                                    className="w-full mb-3 pass-input"
                                    placeholder={t("current_password")}
                                    toggleMask
                                    feedback={false}
                                />
                            </div>
                            <div className="col-lg-6 ">
                                <label
                                    htmlFor="password"
                                    className="block text-900 font-medium mb-2"
                                >
                                    {t("new_password")}
                                </label>
                                <Password
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    className="w-full mb-3 pass-input"
                                    placeholder={t("enter_password")}
                                    toggleMask
                                    feedback={false}
                                />
                            </div>
                            <div className="col-lg-6 ">
                                <label
                                    htmlFor="confirm-password"
                                    className="block text-900 font-medium mb-2"
                                >
                                    {t("new_password_confirm")}
                                </label>
                                <Password
                                    id="confirmPass"
                                    value={confirmPassword}
                                    onChange={confirmPasswordCheck}
                                    className="w-full pass-input mb-2"
                                    placeholder={t("enter_confirm_pass")}
                                    toggleMask
                                    feedback={false}
                                />
                                <small
                                    id="confirmEmail-help"
                                    className="p-error block"
                                    style={{visibility: passwordMatchOrNot ? 'hidden' : 'visible'}}
                                >
                                    {t("passwords_not_match")}
                                </small>
                            </div>
                        </div>
                        <div className="row mx-auto mt-4">
                            <div className="col-lg-4 ">
                                <Button
                                    label={t("update_password")}
                                    style={{width: "100%"}}
                                    className="p-button-raised"
                                    onClick={changePassword}
                                    loading={loading}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </Dialog>
        </>
    );
}

const mapStateToProps = state => {
    return {
        username: state.auth.userDetails.username,
        userId: state.auth.userDetails.id,
    };
};

export default connect(mapStateToProps, null)((ChangePasswordDialogue));