export const colors = [
    "var(--orange-400)",
    "var(--blue-400)",
    "var(--green-400)",
    "var(--pink-400)",
    "var(--yellow-400)",
    "var(--cyan-400)",
    "var(--purple-400)",
    "var(--green-400)",
    "var(--indigo-400)",
    "var(--teal-400)",
    "var(--bluegray-400)",
    "var(--gray-400)",
    "var(--orange-600)",
    "var(--blue-600)",
    "var(--green-600)",
    "var(--pink-600)",
    "var(--yellow-600)",
    "var(--cyan-600)",
    "var(--purple-600)",
    "var(--green-600)",
    "var(--indigo-600)",
    "var(--teal-600)",
    "var(--bluegray-600)",
    "var(--gray-600)",
    "var(--orange-700)",
    "var(--blue-700)",
    "var(--green-700)",
    "var(--pink-700)",
    "var(--yellow-700)",
    "var(--cyan-700)",
    "var(--purple-700)",
    "var(--green-700)",
    "var(--indigo-700)",
    "var(--teal-700)",
    "var(--bluegray-700)",
    "var(--gray-700)"
]