export const isAuthorized = (authorities, authority) => {
    return !!authorities.find(ownedAuthority => (ownedAuthority.authority === authority));
}

export const exportPdf = (cols, dataTableItems) => {
    import('jspdf').then((jsPDF) => {
        import('jspdf-autotable').then(() => {

            const dataArrayForPdf = [];
            // Iterate through the JSON objects and convert them to arrays
            dataTableItems.forEach(function(jsonObject) {
                // Initialize an empty array for each JSON object
                const arrayFromObject = [];
                // Iterate through the keys of the JSON object and push their values to the array
                for (const key in jsonObject) {
                    if (jsonObject.hasOwnProperty(key)) {
                        arrayFromObject.push(jsonObject[key]);
                    }
                }
                // Push the array representing the JSON object into the result array
                dataArrayForPdf.push(arrayFromObject);
            });

            const doc = new jsPDF.default(0, 0);
            doc.setFont('Roboto-Regular', 'normal');
            doc.autoTable({
                columns: cols,
                body: dataArrayForPdf,
                styles: {
                    font: 'Roboto-Regular',
                    fontStyle: 'normal',
                }
            });

            doc.save('report.pdf');
        });
    });
};

export const exportExcel = (dataTableItems) => {
    import('xlsx').then((xlsx) => {
        const worksheet = xlsx.utils.json_to_sheet(dataTableItems);
        const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
        const excelBuffer = xlsx.write(workbook, {
            bookType: 'xlsx',
            type: 'array'
        });

        saveAsExcelFile(excelBuffer, 'report');
    });
};

const saveAsExcelFile = (buffer, fileName) => {
    import('file-saver').then((module) => {
        if (module && module.default) {
            let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
            let EXCEL_EXTENSION = '.xlsx';
            const data = new Blob([buffer], {
                type: EXCEL_TYPE
            });

            module.default.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
        }
    });
};

export const deepObjectCompare = (obj1, obj2) => {
    if (typeof obj1 !== typeof obj2) {
        return false;
    }

    if (Array.isArray(obj1)) {
        if (!Array.isArray(obj2) || obj1.length !== obj2.length) {
            return false;
        }

        for (let i = 0; i < obj1.length; i++) {
            if (!deepObjectCompare(obj1[i], obj2[i])) {
                return false;
            }
        }
    } else if (typeof obj1 === "object" && obj1 !== null && obj2 !== null) {
        const keys1 = Object.keys(obj1);
        const keys2 = Object.keys(obj2);

        if (keys1.length !== keys2.length) {
            return false;
        }

        for (const key of keys1) {
            if (!deepObjectCompare(obj1[key], obj2[key])) {
                return false;
            }
        }
    } else {
        if (obj1 !== obj2) {
            return false;
        }
    }
    return true;
};
