import React, {useState, useEffect, useRef} from 'react';
import {FilterMatchMode} from 'primereact/api';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {InputText} from 'primereact/inputtext';
import {Button} from 'primereact/button';
import {useTranslation} from 'react-i18next';
import {useHistory} from "react-router-dom";
import {Dialog} from 'primereact/dialog';
import {connect} from "react-redux";
import moment from 'moment';
import {exportExcel, exportPdf} from "../shared/utility";
import {SplitButton} from "primereact/splitbutton";
import {PaymentService} from "../service/PaymentService";

const PaymentsPage = (props) => {
    const {t, i18n} = useTranslation();

    let emptyPaymentItem = {
        id: null,
        fullName: null,
        createdDate: null,
        paymentMethod: null,
        notes: null,
        amount: null,
        source: null
    };

    const [payment, setPayment] = useState(emptyPaymentItem);
    const [payments, setPayments] = useState(null);
    const [selectedPayment, setSelectedPayment] = useState(null);
    const [paymentDialog, setPaymentDialog] = useState(false);
    const [deletePaymentDialog, setDeletePaymentDialog] = useState(false);
    const [submitted, setSubmitted] = useState(false);

    const [filters, setFilters] = useState({
        'global': {value: null, matchMode: FilterMatchMode.CONTAINS}
    });

    const exportOptions = [
        {
            label: 'Excel',
            icon: 'pi pi-file-excel',
            command: () => {
                exportExcel(payments);
            }
        },
        {
            label: 'CSV',
            icon: 'pi pi-file',
            command: () => {
                exportCSV();
            }
        }
    ];

    const cols = [
        {field: 'id', header: t('id')},
        {field: 'fullName', header: t('person')},
        {field: 'source', header: t('source')},
        {field: 'createdDate', header: t('creation_date')},
        {field: 'notes', header: t('notes')},
        {field: 'amount', header: t('amount')},
        {field: 'paymentMethod', header: t('payment_method')},
    ];

    const [globalFilterValue, setGlobalFilterValue] = useState('');
    const [loading, setLoading] = useState(false);
    const toast = useRef(null);
    const dt = useRef(null);

    const paymentService = new PaymentService();

    useEffect(() => {
        paymentService.getPayments(props.partnerId).then(data => {
            setPayments(fixDateField(data.content));
            setLoading(false)
        });
    }, []);

    const fixDateField = (data) => {
        return [...data || []].map(d => {
            d.date = new Date(d.date);
            d.fullName = d.customer.fullName;
            return d;
        });
    }

    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = {...filters};
        _filters['global'].value = value;

        setFilters(_filters);
        setGlobalFilterValue(value);
    }

    const addPayment = () => {
        setPayment(emptyPaymentItem);
        setSubmitted(false);
        setPaymentDialog(true);
    }

    const hideDialog = () => {
        setSubmitted(false);
        setPaymentDialog(false);
        setDeletePaymentDialog(false);
    }

    const confirmDeletePayment = (payment) => {
        setPayment(payment);
        setDeletePaymentDialog(true);
    }

    const deletePayment = () => {
        paymentService.deletePayment(payment.id)
            .then(() => {
                let _payments = payments.filter(val => val.id !== payment.id);
                setPayments(_payments);
                setDeletePaymentDialog(false);
                setPayment(emptyPaymentItem);
            })
    }

    const exportCSV = () => {
        dt.current.exportCSV();
    }

    const onSelectionDetail = (e) => {
        setSelectedPayment(e.value)
        setPaymentDialog(true);
    }

    const dateBodyTemplate = (rowData) => {
        if (rowData.dueDate != null) {
            return moment(rowData.dueDate).format('DD.MM.YYYY HH:mm')
        }
        return ''
    }

    const creationDateBodyTemplate = (rowData) => {
        if (rowData.createdDate != null) {
            return moment(rowData.createdDate).format('DD.MM.YYYY HH:mm')
        }
        return ''
    }

    const fullNameBodyTemplate = (rowData) => {
        if (rowData.customer.fullName != null) {
            return rowData.customer.fullName;
        }
        return ''
    }

    const renderHeader = () => {
        return (
            <div className="flex justify-content-between align-items-center">
                <h5 className="m-0">{t('collections')}</h5>
                <span className="p-input-icon-right">
                    <i className="pi pi-export"/>
                    <SplitButton label={t('export')} icon="pi pi-download" className="p-button-help"
                                 style={{marginRight: "10px"}} onClick={() => exportPdf(cols, payments)}
                                 model={exportOptions}/>
                    <span> </span>
                    <i className="pi pi-search"/>
                    <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder={t('search')}/>
                </span>
            </div>
        )
    }

    const header = renderHeader();
    let history = useHistory();

    const actionBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <Button icon="pi pi-trash" className="p-button-rounded p-button-warning"
                        onClick={() => confirmDeletePayment(rowData)}/>
            </React.Fragment>
        );
    }

    const deletePaymentDialogFooter = (
        <React.Fragment>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDialog}/>
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={deletePayment}/>
        </React.Fragment>
    );

    return (
        <div className="datatable-doc-demo col-12">

            <div className="card">
                <DataTable value={payments} ref={dt} paginator className="p-datatable-products" header={header} rows={8}
                           paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                           rowsPerPageOptions={[10, 25, 50]}
                           dataKey="id" rowHover selection={selectedPayment} onSelectionChange={onSelectionDetail}
                           filters={filters} filterDisplay="menu" loading={loading} responsiveLayout="scroll"
                           emptyMessage={t('no_payment_found')}
                           currentPageReportTemplate={t('Showing {first} to {last} of {totalRecords} entries')}>

                    <Column field="fullName" header={t('person')} sortable></Column>
                    <Column field="amount" header={t('amount')} sortable dataType="count"></Column>
                    <Column field="source" header={t('source')} sortable></Column>
                    <Column field="notes" header={t('notes')} sortable></Column>
                    <Column field="paymentMethod" header={t('payment_method')} sortable></Column>
                    <Column field="createdDate" header={t('creation_date')} sortable dataType="date"
                            body={creationDateBodyTemplate}></Column>
                    <Column body={actionBodyTemplate} exportable={false} style={{minWidth: '8rem'}}></Column>
                </DataTable>
            </div>
            <Dialog visible={deletePaymentDialog} style={{width: '450px'}} header={t('confirm')} modal
                    footer={deletePaymentDialogFooter} onHide={hideDialog}>
                <div className="confirmation-content">
                    <i className="pi pi-exclamation-triangle mr-3" style={{fontSize: '2rem'}}/>
                    {payment && <span>{t('confirm_delete')}</span>}
                </div>
            </Dialog>
        </div>
    );
}

const mapStateToProps = state => {
    return {
        partnerId: state.auth.userDetails.partner.id
    };
};

export default connect(mapStateToProps, null)((PaymentsPage));
