import axios from "axios";
export class CreditService {
    postCredit(credit) {
        return axios.post(`${window.env.REACT_APP_GORESY_BACKEND_URL}/credit`, credit)
            .then(res => res.data);
    }

    getCredits(partnerId) {
        return new Promise(resolve => {
            axios.get(`${window.env.REACT_APP_GORESY_BACKEND_URL}/credits-of-partner?partnerId=${partnerId}`).then(res => resolve(res.data));
        });
    }

    deleteCredit(expenseId) {
        return new Promise(resolve => {
            axios.delete(`${window.env.REACT_APP_GORESY_BACKEND_URL}/credit/${expenseId}`).then(res => resolve(res.data));
        });
    }
}