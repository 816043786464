import axios from "axios";
export class DebtService {

    postDebt(debt) {
        return axios.post(`${window.env.REACT_APP_GORESY_BACKEND_URL}/debt`, debt)
          .then(res => res.data);
    }

    getDebts(partnerId) {
        return new Promise(resolve => {
            axios.get(`${window.env.REACT_APP_GORESY_BACKEND_URL}/debts-of-partner?partnerId=${partnerId}`).then(res => resolve(res.data));
        });
    }

    deleteDebt(debtId) {
        return new Promise(resolve => {
            axios.delete(`${window.env.REACT_APP_GORESY_BACKEND_URL}/debts/${debtId}`).then(res => resolve(res.data));
        });
    }

}