import axios from "axios";

export class ReportService {

    getReportStaffSmall() {
        return fetch('staff-report.json').then(res => res.json())
                .then(d => d.data);
    }

    getCommentsSmall() {
        return fetch('comments-small.json').then(res => res.json())
                .then(d => d.data);
    }

    getReportMedium() {
        return fetch('report-large.json').then(res => res.json())
                .then(d => d.data);
    }

    getReportLarge() {
        return fetch('report-large.json').then(res => res.json())
                .then(d => d.data);
    }

    getReportXLarge() {
        return fetch('report-large.json').then(res => res.json())
                .then(d => d.data);
    }

    getReport(params) {
        const queryParams = params ? Object.keys(params).map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k])).join('&') : '';
        return fetch('https://www.primefaces.org/data/Report?' + queryParams).then(res => res.json())
    }

    getCashierReport(partnerId){
        return new Promise((resolve) => {
            axios
                .get(
                    `${window.env.REACT_APP_GORESY_BACKEND_URL}/cashier-report/${partnerId}`
                )
                .then((res) => resolve(res.data));
        });
    }
}