import axios from "axios";
export class PaymentService {

    postPayment(payment) {
        return axios.post(`${window.env.REACT_APP_GORESY_BACKEND_URL}/payments`, payment)
            .then(res => res.data);
    }

    getPayments(partnerId) {
        return new Promise(resolve => {
            axios.get(`${window.env.REACT_APP_GORESY_BACKEND_URL}/payments-of-partner?partnerId=${partnerId}`).then(res => resolve(res.data));
        });
    }

    deletePayment(paymentId) {
        return new Promise(resolve => {
            axios.delete(`${window.env.REACT_APP_GORESY_BACKEND_URL}/payments/${paymentId}`).then(res => resolve(res.data));
        });
    }

}