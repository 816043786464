import React, {useEffect, useRef, useState} from 'react';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {useTranslation} from 'react-i18next';
import {CheckService} from "../service/CheckService";
import {connect} from "react-redux";
import CustomChip from '../components/CustomChip';
import {FilterMatchMode, FilterOperator} from "primereact/api";
import {Calendar} from "primereact/calendar";

const HomePage = (props) => {
    const {t, i18n} = useTranslation();
    const [data, setData] = useState(null);
    const [filters, setFilters] = useState(null);
    const [loading, setLoading] = useState(true);
    const dt = useRef(null);
    const checkServices = new CheckService();

    useEffect(() => {
        checkServices.getChecks(props.partnerId)
            .then(data => {
                setData(getData(data.content));
                setLoading(false)
            })
        initFilters();
    }, []);

    const initFilters = () => {
        setFilters({
            'date': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.DATE_IS}]}
        });
    }

    const getData = (data) => {
        return [...data || []].map(d => {
            d.createdDate = new Date(d.createdDate);
            return d;
        });
    }

    const dateBodyTemplate = (rowData) => {
        return new Date(rowData.createdDate).toLocaleDateString();
    }

    const dateFilterTemplate = (options) => {
        return <Calendar value={options.field} onChange={(e) => options.filterCallback(e.value, options.index)}
                         dateFormat="mm/dd/yy" placeholder="mm/dd/yyyy" mask="99/99/9999"/>
    }

    const servicesBodyTemplate = (rowData) => {
        return rowData.services && rowData.services.length > 0 && rowData.services.map((data, i) => (
            <CustomChip
                key={`${data.name}${i}`}
                label={data.name}
            />
        ))
    }

    const renderHeader = () => {
        return (
            <div className="flex justify-content-between align-items-center">
                <h5 className="m-0">{t('home')}</h5>
            </div>
        )
    }

    return (
        <div className="datatable-doc-demo col-12">
            <div className="card">
                <DataTable value={data}
                           ref={dt}
                           paginator
                           className="p-datatable-data"
                           header={renderHeader}
                           rows={10}
                           paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                           rowsPerPageOptions={[10, 25, 50]}
                           dataKey="id"
                           rowHover
                           filterDisplay="menu"
                           loading={loading}
                           responsiveLayout="scroll"
                           currentPageReportTemplate={t('Showing {first} to {last} of {totalRecords} entries')}
                >
                    <Column field="createdDate"
                            filter
                            filterField={"createdDate"}
                            dataType="date"
                            header={t('date')}
                            body={dateBodyTemplate}
                            filterElement={dateFilterTemplate}
                    ></Column>
                    <Column field="customerFullname" header={t('customers')} sortable></Column>
                    <Column field="services" body={servicesBodyTemplate} header={t('services')} sortable></Column>
                    <Column field="product" header={t('products')} sortable></Column>
                    <Column field="totalAmount" header={t('total_cost')} sortable></Column>
                </DataTable>
            </div>
        </div>
    );
}


const mapStateToProps = state => {
    return {
        partnerId: state.auth.userDetails.partner.id
    };
};

export default connect(mapStateToProps, null)((HomePage));