import React from 'react';

export const AppFooter = (props) => {

    return (
        <div className="layout-footer">
            Copyright 2022
            <span className="font-medium ml-2">Goresy</span>
        </div>
    );
}
